<template>
  <div class="page" style="text-align: left">
    <div class="row">
      <div style="width: 99%">
        <div class="header" id="header" style="width: 100%; height: 60px;padding-top:20px;">
          <div style="width: 11%; float: left">
            <img src="../../assets/print_logo.jpg" style="height: 40px" />
          </div>
          <div style="width: 89%; float: right">
            <p
              style="
                font-size: 24px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b>{{printModel.formModel.editPrintTitle}}</b>
            </p>
            <p
              style="
                font-size: 16px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b><u>材料安全数据表</u></b>
            </p>
          </div>
        </div>
        <div style="clear: both"></div>
        <table
          style="width: 100%; text-align: center; font-size: 12px"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>第  1  节：产品和公司标识信息</b>
            </td>
          </tr>
        </table>
        <!-- Product info -->
        <div>
          <div style="width: 45%; float: left">
            <table style="font-size: 12px">
              <tr>
                <td style="width: 25%"><b>产品名称</b></td>
                <td style="width: 75%">
                  <span t-field="o.name" />{{
                    printModel.formModel.productName
                  }}
                </td>
              </tr>
              <tr v-show="printModel.formModel.versionNumber">
                <td style="width: 25%"><b>版本号</b></td>
                <td style="width: 75%">
                  {{ printModel.formModel.versionNumber }}
                </td>
              </tr>
              <tr v-show="printModel.formModel.itemCode">
                <td style="width: 25%"><b>产品编号</b></td>
                <td style="width: 75%">
                  <span t-field="o.default_code" />{{
                    printModel.formModel.itemCode
                  }}
                </td>
              </tr>
              <tr v-show="printModel.formModel.inciName">
                <td style="width: 25%"><b>INCI  名称</b></td>
                <td style="width: 75%">
                  <span t-field="o.default_code" />{{
                    printModel.formModel.inciName
                  }}
                </td>
              </tr>
              <tr v-show="printModel.formModel.botanicalName">
                <td style="width: 25%"><b>植物名</b></td>
                <td style="width: 75%">
                  <span t-field="o.default_code" />{{
                    printModel.formModel.botanicalName
                  }}
                </td>
              </tr>
              <tr>
                <td><b>紧急联系方式</b></td>
                <td>若发生紧急情况，请拨打 119</td>
              </tr>
              <tr>
                <td><b>物料用途</b></td>
                <td>
                  用作化妆品、个人护理、食品或膳食补充剂的组成成分
                </td>
              </tr>
            </table>
          </div>

          <div style="width: 50%; float: left">
            <table style="width: 100%; font-size: 12px">
              <tr>
                <td style="width: 50%">
                  <b>供应商/生产商</b>
                </td>
                <td v-html="supplierOrProducer"></td>
              </tr>
            </table>
          </div>
        </div>
        <div style="height: 20px"></div>
        <table
          style="width: 100%; text-align: center; font-size: 12px"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>第  2  节：危险标识信息</b>
            </td>
          </tr>
        </table>
        <table style="width: 80%; font-size: 12px">
          <tr>
            <td><b>危险名称</b></td>
            <td>无</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><b>按照EC指令1999/45/EC，本产品不属于危险类物质。</b></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><b>GHS标签要素，包括防范说明</b></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td style="padding-left:50px;"><b>象形图</b></td>
            <td>无关</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td style="padding-left:50px;"><b>信号词</b></td>
            <td>无关</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><b>危险说明</b></td>
            <td>无</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><b>未归类（HNOC）或GHS未涵盖的危害</b></td>
            <td>无</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><b>CHIP/风险/安全分类</b></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><b>与本品有关的风险描述语</b></td>
            <td>无</td>
            <td><b>与本品有关的安全描述语</b></td>
            <td>无</td>
          </tr>
        </table>

        <table
          style="width: 100%; text-align: center; font-size: 12px"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>第  3  节：组份/成分说明</b>
            </td>
          </tr>
        </table>

        <template v-if="printModel.formModel.rowData.length > 0">
          <table
            style="width: 98%; font-size: 12px"
            cellspacing="0"
            class="tab"
          >
            <tr>
              <th><b>物质名称</b></th>
              <th><b>CAS  编号</b></th>
              <th><b>EINECS  编号</b></th>
              <th><b>风险描述语</b></th>
              <th><b>安全描述语</b></th>
            </tr>
            <tr :key="i" v-for="(item, i) in printModel.formModel.rowData">
              <td :key="z" v-for="(cvalue, z) in item.substanceName">
                {{ cvalue }}
              </td>
              <td>{{ item.casNumber }}</td>
              <td>{{ item.einecsNumber }}</td>
              <td>{{ item.rphras }}</td>
              <td>{{ item.sphrase }}</td>
            </tr>
          </table>
        </template>

        <table
          style="width: 100%; text-align: center; font-size: 12px"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>第  4  节：急救措施</b>
            </td>
          </tr>
        </table>
        <table style="width: 100%; font-size: 12px">
          <tr>
            <td style="width:100px;"><b>皮肤接触</b></td>
            <td>
              使用肥皂和冷水彻底冲洗。若症状持续不退，需去医院就诊
            </td>
          </tr>
          <tr>
            <td><b>吸入</b></td>
            <td>
              将患者移至新鲜空气处，松开衣物并寻求专业医疗救助。如果无呼吸，对其进行人工呼吸。
            </td>
          </tr>
          <tr>
            <td><b>眼睛接触</b></td>
            <td>
              查看是否接触眼镜，如有接触，将眼镜/隐形眼镜取下。如果接触眼睛，立即使用大量的冷水冲洗眼睛。若症状持续不退， 需去医院就诊。
            </td>
          </tr>
          <tr>
            <td><b>吞入</b></td>
            <td>
              不要对受害人催吐。不要通过嘴向已昏迷的患者喂食任何东西。若症状持续不退，需去医院就诊。
            </td>
          </tr>
        </table>

        <table
          style="width: 100%; text-align: center; font-size: 12px"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>第  5  节：消防措施</b>
            </td>
          </tr>
        </table>
        <table style="width: 100%; font-size: 12px">
          <tr>
            <td style="width: 200px"><b>产品易燃性</b></td>
            <td></td>
            <td>
              暴露于热或火焰时有轻微的火灾危险。 
            </td>
          </tr>
          <tr>
            <td style="width: 200px"><b>产品易爆性</b></td>
            <td></td>
            <td>
              高于闪点，蒸气混合物可能会引起闪光火。 
            </td>
          </tr>
          <tr>
            <td><b>灭火介质</b></td>
            <td><b>适合</b></td>
            <td>
              可使用周围物品着火适用的各种灭火介质
            </td>
          </tr>
          <tr>
            <td></td>
            <td><b>不适合</b></td>
            <td>无已知的危险产物。</td>
          </tr>
          <tr>
            <td><b>特殊接触危险</b></td>
            <td></td>
            <td>如有火灾，有毒气体和蒸气可能会被释放。</td>
          </tr>
          <tr>
            <td><b>消防员特殊防护用具</b></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td colspan="3">消防员需配备自持式呼吸设备(SCBA) ，该设备需为正压调节式且通过 MSHA/NIOSH 认证（若其他等同的认证产品），且要穿着全套的的防护服。</td>
          </tr>
        </table>

        <table
          style="width: 100%; text-align: center; font-size: 12px"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>第  6  节：意外泄漏防范措施</b>
            </td>
          </tr>
        </table>
        <table style="width: 100%; font-size: 12px">
          <tr>
            <td style="width: 200px"><b>一般信息</b></td>
            <td>
              穿戴适合的防护衣物和用具（见第 8 节）
            </td>
          </tr>
          <tr>
            <td><b>溅出/泄漏</b></td>
            <td>
              用惰性固体（如沙子或土壤）吸收溢出物。 按照地方的规定处理。
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              对于大量溢出物，将泄漏物围起来，并将其泵入合适的容器中。
            </td>
          </tr>
        </table>

        <table
          style="width: 100%; text-align: center; font-size: 12px"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>第  7  节：搬运和存放</b>
            </td>
          </tr>
        </table>
        <table style="width: 100%; font-size: 12px">
          <tr>
            <td><b>搬运</b></td>
            <td>
              在良好通风处搬运且采用能够最大限度地减少雾气或蒸汽形成的搬运方式，同时要使用合适的个人防护用具（PPE）。  
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              避免吸雾气或蒸汽。避免接触皮肤及眼睛。
            </td>
          </tr>
          <tr>
            <td><b>存放</b></td>
            <td>
              在阴凉、干燥且通风良好处，采用密封容器存放。
            </td>
          </tr>
        </table>

        <table
          style="width: 100%; text-align: center; font-size: 12px"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>第  8  节：接触控制和个人防护</b>
            </td>
          </tr>
        </table>
        <b>有关可以接受的接触限值，请咨询当地监管部门</b>
        <table style="width: 80%; font-size: 12px">
          <tr>
            <td style="width: 130px"><b>工程措施</b></td>
            <td>建议的局部排放方式</td>
            <td></td>
          </tr>
          <tr>
            <td><b>卫生措施</b></td>
            <td>
              遵守良好的生产规程标准。接触本品之前和之后，要清洗双手、前臂、面部以及接触本品的其他所有部位。
            </td>
            <td></td>
          </tr>
        </table>
        <table style="font-size: 12px">
          <tr>
            <td><b>个人防护</b></td>
            <td><b>眼睛</b></td>
            <td>护目镜</td>
          </tr>
          <tr>
            <td></td>
            <td><b>皮肤</b></td>
            <td>试验室工作服、工作靴以及合适的试验室衣物</td>
          </tr>
          <tr>
            <td></td>
            <td><b>双手</b></td>
            <td>一次性橡胶或乙烯手套</td>
          </tr>
          <tr>
            <td></td>
            <td><b>呼吸系统</b></td>
            <td>
              在本品的正常和目标使用情况下，无需使用呼吸器
            </td>
          </tr>
        </table>

        <table
          style="width: 100%; text-align: center; font-size: 12px"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>第  9  节：物理和化学特性</b>
            </td>
          </tr>
        </table>
        <table style="width: 100%; font-size: 12px">
          <tr>
            <td style="width: 140px"><b>外观</b></td>
            <td style="width: 200px">{{ printModel.formModel.appearance }}</td>
            <td style="width: 140px"><b>沸点：</b></td>
            <td style="width: 50px">不适用</td>
            <td style="width: 260px"><b>分子式</b></td>
            <td style="width: 50px">无</td>
          </tr>
          <tr>
            <td><b>颜色</b></td>
            <td>{{ printModel.formModel.color }}</td>
            <td><b>融点</b></td>
            <td>不适用</td>
            <td><b>分子量</b></td>
            <td>无</td>
          </tr>
          <tr>
            <td><b>气味</b></td>
            <td>{{ printModel.formModel.odor }}</td>
            <td><b>粘度</b></td>
            <td>不适用</td>
            <td><b>分解温度</b></td>
            <td>无</td>
          </tr>
          <tr>
            <td><b>溶解度</b></td>
            <td>{{printModel.formModel.solubility}}</td>
            <td><b>闪点</b></td>
            <td>无</td>
            <td><b>自燃温度</b></td>
            <td>无</td>
          </tr>
          <tr>
            <td><b>pH  值</b></td>
            <td>{{ printModel.formModel.ph }}</td>
            <td><b>蒸汽压力</b></td>
            <td>无</td>
            <td><b>爆炸性/爆炸极限</b></td>
            <td>无</td>
          </tr>
          <tr>
            <td><b>蒸发率</b></td>
            <td>无</td>
            <td><b>蒸汽密度</b></td>
            <td>无</td>
            <td><b>氧化性</b></td>
            <td>无</td>
          </tr>
          <tr>
            <td><b>比重</b></td>
            <td>无</td>
            <td><b>比密度</b></td>
            <td>无</td>
            <td><b></b></td>
            <td></td>
          </tr>
        </table>

        <table
          style="width: 100%; text-align: center; font-size: 12px"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>第  10  节：稳定性和反应性</b>
            </td>
          </tr>
        </table>
        <table style="width: 100%; font-size: 12px">
          <tr>
            <td style="width: 130px"><b>化学稳定性</b></td>
            <td>
              在正常温度、湿度和压力下稳定
            </td>
          </tr>
          <tr>
            <td><b>需避免的情况</b></td>
            <td>避免过热和明火。 避免与强氧化剂或可燃材料接触。</td>
          </tr>
          <tr>
            <td><b>与其他材料的不相容性</b></td>
            <td>与高氯酸，氧化铅，乙酸酐，硝基苯，氯，过氧化物，强氧化剂，强酸，强碱不相容。 易燃。</td>
          </tr>
          <tr>
            <td><b>危险分解产物</b></td>
            <td>热分解释放腐蚀性烟气或丙烯醛。分解可能导致一氧化碳和二氧化碳的形成。</td>
          </tr>
          <tr>
            <td><b>危险聚合</b></td>
            <td>无已知的危险产物。</td>
          </tr>
        </table>

        <table
          style="width: 100%; text-align: center; font-size: 12px"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>第  11  节：有毒性说明</b>
            </td>
          </tr>
        </table>
        <table style="width: 100%; font-size: 12px">
          <tr>
            <td style="width: 130px"><b>接触途径</b></td>
            <td>吞食、皮肤接触、吸入</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><b>急性效应</b></td>
            <td>LD50 N/A LC50 N/A</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><b>潜在慢性健康影响</b></td>
            <td></td>
            <td>重复的过度暴露可能导致血脂水平升高。</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td style="width: 130px"><b>致癌性</b></td>
            <td></td>
            <td><b>IRAC：</b>无</td>
            <td><b>OSHA：</b>无</td>
            <td><b>ACGIH：</b>无</td>
            <td><b>诱变效应：</b>无</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td><b>NTP：</b>无</td>
            <td><b>EU：</b>无</td>
            <td><b>其他：</b>无</td>
            <td><b>致畸作用：</b>无</td>
          </tr>
        </table>

        <table
          style="width: 100%; text-align: center; font-size: 12px"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>第  12  节：生态影响说明</b>
            </td>
          </tr>
        </table>
        <table style="width: 100%; font-size: 12px">
          <tr>
            <td style="width: 130px"><b>环保注意事项</b></td>
            <td>无已知的明显反应或显著危险</td>
          </tr>
          <tr>
            <td><b>生物降解产物</b></td>
            <td>
              产品本身及其生物降解产物无已知的明显生物毒性。
            </td>
          </tr>
        </table>

        <table
          style="width: 100%; text-align: center; font-size: 12px"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>第  13  节：废弃处理注意事项</b>
            </td>
          </tr>
        </table>
        <table style="width: 100%; font-size: 12px">
          <tr>
            <td style="width: 130px"><b>废弃处理方法</b></td>
            <td>
              必须按照国家以及当地环境监管法规进行废弃处理。
            </td>
          </tr>
        </table>

        <table
          style="width: 100%; text-align: center; font-size: 12px"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>第  14  节：运输说明</b>
            </td>
          </tr>
        </table>
        <table style="width: 100%; font-size: 12px">
          <tr>
            <td style="width: 130px"><b>DOT  分类</b></td>
            <td>非运输管制物质。</td>
          </tr>
          <tr>
            <td><b>海关分类</b></td>
            <td>{{printModel.formModel.customsClassification}}</td>
          </tr>
        </table>

        <table
          style="width: 100%; text-align: center; font-size: 12px"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>第  15  节：管控信息</b>
            </td>
          </tr>
        </table>
        <table style="width: 100%; font-size: 12px">
          <tr>
            <b>危险物质指令  GB16483或者GB17519：</b>
          </tr>
          <tr>
            按照 GB16483或者GB17519，本产品未被归入危险类物质。
          </tr>
          <tr>
            <b>OSHA 许可接触限值 (PEL)、ACGIH 阈值(TLV) 或其他接触限值：</b>
          </tr>
          <tr>
            见第8节。
          </tr>
        </table>

        <table
          style="width: 100%; text-align: center; font-size: 12px"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>第  16  节：其他信息</b>
            </td>
          </tr>
        </table>
        <table style="width: 100%; font-size: 12px">
          <tr>
            <td><b>创建日期</b></td>
            <td>{{ formatDate(printModel.formData.createTime) }}</td>
            <td><b>最后更新日期</b></td>
            <td>{{ formatDate(printModel.formData.updateTime) }}</td>
            <td><b>更新人</b></td>
            <td>{{ printModel.formData.editName  }}</td>
          </tr>
        </table>
        <p style="font-size: 12px; margin: 0px; padding: 0px">
          本数据表中所包含的信息为本公司认为准确的信息，但不保证是由本公司自己原创编制。建议本数据表的使用方在使用前，确证本数据表是否为现行的适用数据表，及是否适用于其自己的应用环境。
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import util from '../../common/base'
export default {
  name: "CSFMU",
  props: ["printModel"],
  data() {
    return {
      username: "",
      loginName: localStorage.getItem("loginUserNameKey"),
    };
  },
  computed:{
    supplierOrProducer(){
        return this.printModel.formModel.supplierOrProducer.replace(/\r\n/g,"<br/>")
    }
  },
  methods: {
    getTime(){
      return util.methods.getLocalTime(-7);
    },
    formatDate(time){
      return util.methods.formatDate({ date: time });
    }
  },
  created() {
    let userInfo = JSON.parse(localStorage.getItem("specUserInfo"));
    if (userInfo) {
      localStorage.setItem("loginUserNameKey", userInfo.username);
    }
  },
};
</script>

<style scoped>
b {
  font-size: 14px;
}
.tab {
  margin-top: 5px;
}
table,
p {
  margin: 0px;
  padding: 0px;
  font-size: 10px;
}
</style>