<template>
  <div style="height:100vh;overflow:scroll;">
    <div class="app-new-item">
      <div id="printDiv"  v-show="printShow">
        <SDSPowder :printModel="this.$data" style="height-max: 1000px; width: 100vw" v-if="formModel.sdsType=='TY-P'"></SDSPowder>
        <SDSLiquid :printModel="this.$data" style="height-max: 1000px; width: 100vw" v-if="formModel.sdsType=='TY-L'"></SDSLiquid>
        <SDSOil :printModel="this.$data" style="height-max: 1000px; width: 100vw" v-if="formModel.sdsType=='TY-Oil'"></SDSOil>
      </div>

      <div class="new-item-head">
        SDS Import {{ titleType }}
        <span v-if="showVerified">
          <i
            :class="verify_show.icon"
            :style="{ color: verify_show.color }"
          ></i>
        </span>
        <span
          style="position: absolute; left: 10px; cursor: pointer"
          @click="back"
        >
          <i class="el-icon-arrow-left"></i>
        </span>
        <span
          style="
            position: absolute;
            right: 10px;
            cursor: pointer;
            font-weight: 400;
            font-size: 14px;
          "
        >
          <el-dropdown placement>
            <div>
              <i class="el-icon-user" style="margin-right: 10px"></i>
              {{ userInfo.username }}
              <i class="el-icon-arrow-down"></i>
              <!-- <span class="user-name">语言切换</span> -->
              <el-dropdown-menu class="user-dropdown" slot="dropdown">
                <el-dropdown-item @click.native="logoutHandle"
                  >Logout</el-dropdown-item
                >
              </el-dropdown-menu>
            </div>
          </el-dropdown>
        </span>
      </div>
    </div>

    <div
      style="
        background: white;
        margin-top: 40px;
        width: 90%;
        margin-left: 2.5%;
        padding: 40px;
      "
    >
      <div
        style="font-size: 20px; float: left; height: 20px; margin-left: 20px"
      >
        基础信息
      </div>
      <div style="margin-top: 40px"></div>
      <div style="position: absolute;text-align: left;margin: 0 0 0 60px;color: red;z-index: 9;">
        <div style="height: 30px;line-height: 30px;">Rev：{{formModel.revNumber}}</div>
        <div style="height: 30px;line-height: 30px;">Date：{{revDateTime!=""?formatTime(revDateTime,"yyyy-MM-dd hh:mm"):revDateTime}}</div>
      </div>
      <center>
        <el-form
          label-position="right"
          label-width="310px"
          style="width: 900px; align: center"
          ref="formModel"
          :model="formModel"
        >
          <el-form-item label="产品编号">
            <el-autocomplete
              clearable
              ref="itemCode"
              v-model="formModel.itemCode"
              :fetch-suggestions="codeSearch"
              placeholder="请输入产品编号"
              popper-class="select-option"
              :popper-append-to-body="false"
              style="width: 100%"
              @select="itemCodeSearch"
              @blur="itemCodeSearch"
            ></el-autocomplete>
          </el-form-item>
          <el-form-item label="版本号">
            <el-input
              v-model="formModel.versionNumber"
              placeholder="请输入版本号"
            ></el-input>
          </el-form-item>
          <el-form-item label="SDS 类型" prop="region">
            <el-select
              ref="sdsType"
              v-model="formModel.sdsType"
              placeholder="请选择类型"
              clearable
              style="width: 100%"  @change="selectSDSType"
            >
              <el-option
                v-for="(item, i) in sdsTypeList"
                :key="i"
                :label="item.label"
                :value="item.value" 
              ></el-option>
            </el-select>
          </el-form-item>
          <div>{{testTime}}</div>
          <el-form-item label="产品名称">
            <el-input
              v-model="formModel.productName"
              placeholder="请输入产品名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="植物名">
            <el-input
              v-model="formModel.botanicalName"
              placeholder="请输入植物名"
            ></el-input>
          </el-form-item>
          <el-form-item label="INCI 名称">
            <el-input
              v-model="formModel.inciName"
              placeholder="请输入INCI 名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="外观">
            <el-input
              v-model="formModel.appearance"
              placeholder="请输入外观"
            ></el-input>
          </el-form-item>
          <el-form-item label="pH">
            <el-input
              v-model="formModel.ph"
              placeholder="请输入pH"
            ></el-input>
          </el-form-item>
          <el-form-item label="颜色">
            <el-input
              v-model="formModel.color"
              placeholder="请输入颜色"
            ></el-input>
          </el-form-item>
          <el-form-item label="气味">
            <el-input
              v-model="formModel.odor"
              placeholder="请输入气味"
            ></el-input>
          </el-form-item>
          <el-form-item label="溶解度">
            <el-input
              v-model="formModel.solubility"
              placeholder="Please Input"
            ></el-input>
          </el-form-item>
          <el-form-item label="海关分类">
            <el-input
              v-model="formModel.customsClassification"
              placeholder="Please Input"
            ></el-input>
          </el-form-item>
          <el-form-item label="供应商/生产商">
            <el-input
              type="textarea"
              rows="6"
              v-model="formModel.supplierOrProducer"
              placeholder="请输入供应商/生产商"
            ></el-input>
          </el-form-item>
          <el-form-item label="编辑打印标题">
            <el-input
              v-model="formModel.editPrintTitle"
              placeholder="请输入编辑打印标题"
            ></el-input>
          </el-form-item>
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>行数据</span>
              <!-- <el-button
                style="float: right"
                type="primary"
                icon="el-icon-circle-plus-outline"
                @click="addRowHandle"
                >添加行</el-button
              > -->
            </div>
            <div
              class="text item"
              :key="i"
              v-for="(item, i) in formModel.rowData"
            >
              <el-form-item label="物质名称">
                <!-- <el-input v-model="formModel.botanicalName" placeholder="请输入"></el-input> -->
                <div
                  style="display: inline-block; width: 615px; text-align: left"
                >
                  <div
                    style="
                      display: flex;
                      justify: flex-start;
                      flex-wrap: wrap;
                      align-items: center;
                    "
                  >
                    <el-tag
                      :key="ti"
                      v-for="(tag, ti) in item.substanceName"
                      closable
                      :disable-transitions="false"
                      @click="tagHandle(item.substanceName,tag,ti)"
                      @close="handleClose(i,tag)"
                      >{{ tag }}</el-tag
                    >
                    <el-input
                      class="input-new-tag"
                      v-if="inciNameInputVisible"
                      v-model="inciNameInputValue"
                      ref="saveTagInput"
                      size="small"
                      @keyup.enter.native="
                        handleInputConfirm(
                          'inciNameInputValue',
                          'inciNameInputVisible',
                          i
                        )
                      "
                      @blur="
                        handleInputConfirm(
                          'inciNameInputValue',
                          'inciNameInputVisible',
                          i
                        )
                      "
                    ></el-input>
                    <el-button
                      v-else
                      class="button-new-tag"
                      size="small"
                      @click="showInput('inciNameInputVisible', i)"
                      >+ 添加物质名称</el-button
                    >
                    <el-button class="button-new-tag" size="small" icon="el-icon-circle-plus-outline" 
                    @click="addRowHandle">添加行</el-button>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="CAS  编号">
                <el-input
                  v-model="item.casNumber"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item label="EINECS  编号">
                <el-input
                  v-model="item.einecsNumber"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item label='风险描述语'>
                <el-input
                  v-model="item.rphras"
                ></el-input>
              </el-form-item>
              <el-form-item label='安全描述语'>
                <el-input
                  v-model="item.sphrase"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>

              <el-button
                v-if="i + 1 > 1"
                type="danger"
                icon="el-icon-delete"
                circle
                @click="deleteRowHandle(i)"
              ></el-button>
              <el-divider></el-divider>
            </div>
          </el-card>

        </el-form>
        <!-- <NoteTableData style="margin-top: 20px" :baseInfo="this.$data"></NoteTableData> -->
        <div style="margin-top: 20px;width: 100%;">
          <el-form :inline="true" class="demo-form-inline">
            <div v-for="(item, index) in noteTableData" :key="index">
              <el-form-item label="修订日期">
                <el-input
                  v-model="item.revisionDate"
                  placeholder="修订日期"
                  style="width: 140px"
                  :disabled="item.isComplete"
                ></el-input>
              </el-form-item>
              <el-form-item label="提出修订人">
                <el-input
                  v-model="item.requestedBy"
                  placeholder="提出修订人"
                  style="width: 140px"
                  :disabled="item.isComplete"
                ></el-input>
              </el-form-item>
              <el-form-item label="修订项目">
                <el-input
                  v-model="item.itemsRevised"
                  placeholder="修订项目"
                  style="width: 140px"
                  :disabled="item.isComplete"
                ></el-input>
              </el-form-item>
              <el-form-item label="修订人">
                <el-input
                  v-model="item.editedBy"
                  placeholder="修订人"
                  style="width: 140px"
                  :disabled="item.isComplete"
                ></el-input>
              </el-form-item>
              <el-form-item label="修订原因">
                <el-input
                  v-model="item.reason"
                  placeholder="修订原因"
                  style="width: 140px"
                  :disabled="item.isComplete"
                ></el-input>
              </el-form-item>
              <el-form-item label="批准人">
                <el-input
                  v-model="item.approvedBy"
                  placeholder="批准人"
                  style="width: 140px"
                  disabled="disabled"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item style="width: 120px">
                <el-button
                  type="text"
                  @click="addNoteTableClick(index)"
                  v-if="
                    item.revisionDate &&
                      item.requestedBy &&
                      item.itemsRevised &&
                      item.editedBy &&
                      item.reason &&
                      item.approvedBy
                  "
                  >Complete</el-button
                >
                &nbsp;&nbsp;
                <el-button
                  type="text"
                  v-if="!item.isComplete && baseInfo.noteTableData.length > 1"
                  @click="removeNoteTableClick(index)"
                >
                  <i style="color:Red;font-size:18px;" class="el-icon-delete"></i>
                </el-button>
              </el-form-item> -->
            </div>
          </el-form>
        </div>
        <div style="margin-top: 20px">
          <el-button v-if="userInfo.operateStatus && formData.id!=0 && formData.verifiedList==0" type="warning" @click="handleNew('Continue Edit',1)">新增至 Verify List</el-button>
          <el-button v-if="userInfo.operateStatus" type="primary" @click="handleNew('ContinueEdit')">保存</el-button>
          <el-button v-if="userInfo.operateStatus" type="primary" @click="handleNew('Save&New')">保存并新增</el-button>
          <el-button
            :icon="isVerifing ? 'el-icon-loading' : ''"
            v-if="showVerified && pemission(userInfo.username) && userInfo.operateStatus"
            type="warning"
            @click="vertify"
            >审核</el-button
          >
          <el-button @click="back">返回首页</el-button>
          <el-button
            type="success"
            icon="el-icon-printer"
            @click="printPage()"
            >打印</el-button>
        </div>
      </center>
    </div>
  </div>
</template>

<script>
import base from "@/common/base.js";
import printJS from "print-js";
import SDSPowder from "../PrintProductTypeModule/Cn-SDS-Powder.vue";
import SDSLiquid from "../PrintProductTypeModule/Cn-SDS-Liquid";
import SDSOil from "../PrintProductTypeModule/Cn-SDS-Oil";
// import NoteTableData from "./NoteTableData";

export default {
  name: "new-item",
  mixins: [base],
  components: { SDSPowder,SDSLiquid,SDSOil },
  data() {
    return {
      printShow: false,
      isVerifing: false,
      showVerified: false,
      titleType: "Add",
      checkProductType: false,
      sdsTypeList: [
        { label: "TY-P", value: "TY-P" },
        { label: "TY-L", value: "TY-L" },
        { label: "TY-Oil", value: "TY-Oil" },
      ],
      inciNameInputVisible: "",
      inciNameInputValue: "",
      newItemType: "New",
      itemCodeSearchLock: "",
      revDateTime:"",//base.methods.getLocalNowTime(),
      // addRevNumber:true,
      pageType:'sds',
      formModel: {
        editPrintTitle: "Draco Natural Product 公司",
        itemCode: "",
        sdsType: "",
        productName: "",
        botanicalName: "",
        inciName: "",
        appearance: "",
        ph: "无",
        color: "",
        ordor: "",
        taste: "",
        supplierOrProducer:"Draco Natural Products, Inc	\r\n539 Parrott Street	\r\nSan Jose, CA 95112 USA	\r\n电话：408-287-7871	\r\n传真：408-287-8838	\r\nwww.draconatural.com	",
        rowData: [
          {
            substanceName: [],
            casNumber: "",
            einecsNumber: "",
            rphras: "无",
            sphrase: "无",
          },
        ],
        versionNumber: "",
        revNumber:0,
        revDate:[],
        noteTableData:"",
        solubility:"可溶于/可分散于水",
      },
      printObj: {
        id: "printDiv", // 这里是要打印元素的ID
        popTitle: "", // 打印的标题
        extraCss: "", // 打印可引入外部的一个 css 文件
        extraHead: "", // 打印头部文字
      },
      requestsubstanceName:{
        substanceName:"",
      },
      formData: {
        id: 0,
        productNumber: "",
        productName: "",
        itemCode: "",
        inciName: "",
        editName:"",
        updateTime:base.methods.getLocalNowTime(),
        createTime:base.methods.getLocalNowTime(),
        jsonValue: {},
      },
      testTime:"",
      noteTableData: [
        {
          revisionDate: "",
          requestedBy: "",
          itemsRevised: "",
          editedBy: "",
          reason:"",
          approvedBy: "",
          isComplete:false,
        },
      ],
      specUserInfo:{}
    };
  },
  computed: {
    verify_show: {
      get() {
        switch (this.formData.confirmed) {
          case 1:
            return { icon: "el-icon-success", color: "#67c23a" };
          case 2:
            return { icon: "el-icon-question", color: "#a6a9ad" };
          default:
            return { icon: "el-icon-error", color: "#f56c6c" };
        }
      },
      set() {
        switch (this.formData.confirmed) {
          case 1:
            return { icon: "el-icon-success", color: "#67c23a" };
          case 2:
            return { icon: "el-icon-question", color: "#a6a9ad" };
          default:
            return { icon: "el-icon-error", color: "#f56c6c" };
        }
      },
    },
  },
  watch: {},
  methods: {
    printPage() {
      this.printShow = true;
      const style = "@page {margin:0} @media print{}";
      var printFileName = this.formModel.productName.replace(':','~').replace('：','~');
      document.title = "(中文SDS)"+printFileName+"Rev."+this.formModel.revNumber;
      setTimeout(() => {
        printJS({
          printable: "printDiv",
          type: "html",
          style: style,
          scanStyles: false,
        });
      }, 1.5);

      setTimeout(() => {
        this.printShow = false;
      }, 3);
    },
    back() {
      this.$router.push({ path: "/cn-home" });
    },
    codeSearch(queryString, cb) {
      // this.getDetailByItemCodeSearch(queryString);
      // var restaurants = this.$store.state.codeList;
      var codeList =  JSON.parse(localStorage.getItem('itemCodeZhCnListKey'));
      var list = [];
      codeList.forEach(element => {
        list.push({value:element.itemCode});
      });
      // var restaurants = localStorage.getItem('itemCodeListKey');
      var results = queryString
        ? list.filter(this.createFilter(queryString))
        : list;
      // 调用 callback 返回建议列表的数据
      
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !=
            -1 || restaurant.value.indexOf(queryString) != -1
        );
      };
    },
    itemCodeSearch(val) {
      if (typeof val.value != "undefined") {
        console.info(val.value);
        this.getDetailByItemCodeSearch(val.value);
      }
    },
    getDetailByItemCodeSearch(searchValue) {
      if (this.itemCodeSearchLock == searchValue) {
        this.itemCodeSearchLock = searchValue;
        return;
      }
      if (typeof searchValue == "undefined" || searchValue.length == 0) {
        return;
      }

      this.$api.handleCnSpec
        .getCnDetailByCnItemCode(searchValue)
        .then((res) => {
          if (res != null) {
            console.log('res==========')
            console.log(res)
            var jsonValue
            if(res.langType=="ch"){
              jsonValue=JSON.parse(res.jsonValue)
            }
            else{
              jsonValue=res;
            }
            if(jsonValue.productType=="TY-EHXTO" ||jsonValue.productType=="TY-SHXTO"){
              this.formModel.sdsType="TY-Oil"
            }
            else if(jsonValue.productType=="TY-P" ||jsonValue.productType=="TY-L"){
              this.formModel.sdsType=jsonValue.productType
            }
            else if (
              jsonValue.productType == "CSFMU-B" || jsonValue.productType == "CSFMU-S" || jsonValue.productType == "REXT" || 
              jsonValue.productType == "F&V" || jsonValue.productType == "FMU-D" || jsonValue.productType == "HEXT" || 
              jsonValue.productType == "OEXT" || jsonValue.productType == "PCG" || jsonValue.productType == "PCG-CSFMU-S" || 
              jsonValue.productType == "SDNP" || jsonValue.productType == "PCG-CSFMU-B"
            ) {
              this.formModel.sdsType="TY-P"
            }
            else if(jsonValue.productType == "Liquid-S" || jsonValue.productType == "Liquid-B" || jsonValue.productType == "SDNP-L" || 
            jsonValue.productType == "Liquid EXT-B" || jsonValue.productType == "Liquid EXT-S"){
              this.formModel.sdsType="TY-L"
            }
            else if(jsonValue.productType == "SHXTO" || jsonValue.productType == "EHXTO"){
              this.formModel.sdsType="TY-Oil"
            }
            else{
              this.formModel.sdsType=jsonValue.productType
            }
            console.log('jsonValue==========')
            console.log(jsonValue)
            this.formModel.productName = res.productName;            
            this.formModel.botanicalName = jsonValue.botanicalName.replace(/_/g,",");
            this.formModel.appearance = jsonValue.appearance;
            // 更新溶解度
            this.formModel.solubility = jsonValue.solubility;
            
            if(jsonValue.productType=="TY-P"){
              this.formModel.color = jsonValue.specificationColor;
              this.formModel.odor = jsonValue.specificationOrdor;
              this.formModel.taste = jsonValue.specificationTaste;
            }
            else{
              this.formModel.color = jsonValue.color;
              this.formModel.odor = jsonValue.odor;
              this.formModel.taste = jsonValue.taste;
            }
            if(this.formModel.sdsType=="TY-L"){
              this.formModel.ph=jsonValue.analysisRangePh;
              this.formModel.odor = jsonValue.ordor;
            }
            // this.formModel.color = jsonValue.specificationColor;
            this.formModel.inciName = jsonValue.inciName.replace(/_/g,"(and)"); 
            if(typeof(jsonValue.inciName) !='undefined' && jsonValue.inciName.length > 0){
              this.formModel.rowData=[]
              if(jsonValue.inciName.indexOf("(and)")>-1){
                for(let item of this.formModel.inciName.split("(and)")){
                  this.formModel.rowData.push(
                    {
                      substanceName: [item],
                      casNumber: "",
                      einecsNumber: "",
                      rphras: "",
                      sphrase: "",
                    }
                  )
                }
                for(let item of this.formModel.inciName.split("(and)")){
                  this.getSdsMatchUrl(item)
                }
              }
              else{
                for(let item of this.formModel.inciName.split("_")){
                  this.formModel.rowData.push(
                    {
                      substanceName: [item],
                      casNumber: "",
                      einecsNumber: "",
                      rphras: "",
                      sphrase: "",
                    }
                  )
                }
                for(let item of this.formModel.inciName.split("_")){
                  this.getSdsMatchUrl(item)
                }
              }
            }
            else if(typeof(jsonValue.botanicalName) !='undefined' && jsonValue.botanicalName.length > 0){
              this.formModel.rowData=[]
              for(let item of this.formModel.botanicalName.split(",")){
                  this.formModel.rowData.push(
                    {
                      substanceName: [item],
                      casNumber: "",
                      einecsNumber: "",
                      rphras: "",
                      sphrase: "",
                    }
                  )
              }
              for(let item of this.formModel.botanicalName.split(",")){
                this.getSdsMatchUrl(item)
              }
            }
            this.formModel.versionNumber = jsonValue.versionNumber;

          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getDetailById(params) {
      this.loading = true;
      this.$api.handleCnSds.getSdsDetail(params).then((data) => {
        this.formData = data;
        // this.formModel = JSON.parse(data.jsonValue);

        let jsonValue = JSON.parse(data.jsonValue)
        console.info('typeof(jsonValue.customsClassification):',typeof(jsonValue.customsClassification))
          if(typeof(jsonValue.customsClassification)=="undefined"){
            jsonValue.customsClassification = "HTS#1302.19.9140";
          }
          this.formModel = jsonValue;

        
        if(!this.formModel.solubility){
          this.formModel.solubility = "可溶于/可分散于水"
        }
        if(this.formModel.noteTableData){
          this.noteTableData = JSON.parse(this.formModel.noteTableData);
        }
        let revNumber = 0
        this.noteTableData.forEach(item=>{
          if(item.isComplete){
            revNumber ++
          }
        })
        if(this.formModel.revNumber == undefined){
          this.formModel.revNumber = 0
        }
        if(this.formModel.revDate == undefined){
          this.formModel.revDate = []
        }
        console.log('this.formModel.revDate')
        console.log(this.formModel.revDate)
        if(this.formModel.revDate.length>0){
          let revDate = this.formModel.revDate[this.formModel.revDate.length-1]
          if(revDate){
            this.revDateTime = revDate
          }
        }
        if(revNumber==0){
          this.revDateTime = ""
        }
        else {
          if(!this.revDateTime){
            this.revDateTime = data.updateTime
          }
        }
        this.formModel.revNumber = revNumber
        this.formModel.rowData.forEach(element => {
          let array = element.substanceName;
          element.substanceName = []
          array.forEach(subItem=>{
            if(subItem.trim()!=""){
              element.substanceName.push(subItem)
            }
          })
        });
        // this.revDateTime = data.updateTime
      });
    },
    async handleNew(type,verifyType=0) {
      let params = { ...this.$route.query };
      this.formModel.rowData.forEach((element) => {
        element.substanceName.join("_");
      });
      this.formModel.noteTableData = JSON.stringify(this.noteTableData)
      let revNumber = 0
      this.noteTableData.forEach(item=>{
        if(item.isComplete){
          revNumber ++
        }
      })
      this.formModel.revNumber = revNumber
      // if (this.formModel.id != null) {
      //   this.formModel.revDate.push(this.revDateTime)
      // }

      this.formData.productName = this.formModel.productName
      this.formData.itemCode = this.formModel.itemCode
      this.formData.inciName = this.formModel.inciName
      this.formData.jsonValue = JSON.stringify(this.formModel)
      this.formData.verifiedList = 0
      if(verifyType == 1){
        this.formData.verifiedList = 1
      }
      if(verifyType == 2){
        this.formData.verifiedList = 0
      }
      console.info(this.formModel);
      if(params.id!=null){
        this.formData.id = params.id
        this.$api.handleCnSds
          .editSdsItem(this.formData)
          .then(() => {
            this.loading = false;
            this.$message({
              type: "success",
              message: '保存成功',
            });
            this.titleType = "Edit";
            this.reloadPage(type);
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      }else{
        this.$api.handleCnSds
          .addSdsItem(this.formData)
          .then(() => {
            this.loading = false;
            this.$message({
              type: "success",
              message: '保存成功',
            });
            this.titleType = "Add";
            this.reloadPage(type);
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      }
    },
    reloadPage(type){
      if (type == "Save&New") {
        //清空数据
        this.$router.push({
          path: "cn-detail-sds",
          query: { id: undefined },
        });
        this.$router.go(0);
      }
    },
    handleClose(arrIndex,tag) {
      this.formModel.rowData[arrIndex].substanceName.splice(
        this.formModel.rowData[arrIndex].substanceName.indexOf(tag),
        1
      );
    },
    addRowHandle() {
      this.formModel.rowData.push({
        substanceName: [],
        casNumber: "",
        einecsNumber: "",
        rphras: "无",
        sphrase: "无",
      });
    },
    deleteRowHandle(index) {
      console.info(index )
      this.formModel.rowData.splice(index,1);
    },
    vertify() {
      let arrtr = this.noteTableData[this.noteTableData.length-1]
      if(arrtr.revisionDate =='' || arrtr.requestedBy =='' || arrtr.itemsRevised =='' || arrtr.editedBy =='' || arrtr.reason ==''){
        this.$message({ type: "info", message: "Revision note 不能为空" });
        return
      }
      this.$confirm("确认要审核吗?", "提示", {
        confirmButtonText: "审核",
        cancelButtonText: "取消",
        type: "warning",
        distinguishCancelAndClose: true,
      })
      .then(() => {
        this.$api.handleCnSds.cnverfiedSdsUrl({id: this.$route.query.id,note: "",})
        .then((data) => {
          console.log(data);
          this.$message({ 
            type: "success", 
            message: "审核成功"
          });
          this.addRevNumber()
        })
        .catch(() => {
        });
      })
      .catch(() => {
      });
    },
    addRevNumber(){
      let noteCount = this.noteTableData.length
      this.noteTableData.forEach((item,i)=>{
        if(i == noteCount-1){
          item.approvedBy = this.specUserInfo.username
          item.isComplete = true
        }
      })
      this.noteTableData.push({
        revisionDate:"",
        requestedBy:"",
        itemsRevised:"",
        editedBy:"",
        approvedBy:"",
        reason:"",
        isComplete:false,
      });
      console.log('this.noteTableData')
      console.log(this.noteTableData)
      this.revDateTime = base.methods.getLocalNowTime()
      this.formModel.revDate.push(this.revDateTime)
      this.handleNew('Continue Edit',2)
    },
    showInput(inputVisible, index) {
      this[inputVisible] = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput[index].$refs.input.focus();
      });
    },
    tagHandle(array,tagCotext,index){//tag标签点击事件
      console.info(array,tagCotext,index );
      this.$prompt('修改值-->'+tagCotext, '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        array.splice(index,1,value)
      }).catch(() => {
          
      });
    },
    handleInputConfirm(value, visible, index) {
      let inputValue = this[value];
      if (inputValue) {
        console.info(this.inciNameInputValue);
        this.getSdsMatchUrl(this.inciNameInputValue)
        this.formModel.rowData[index].substanceName.push(
          this.inciNameInputValue
        );
      }

      this[visible] = false;
      this[value] = "";
    },
    selectSDSType(){
      if(this.formModel.sdsType=="TY-P" || this.formModel.sdsType=="TY-Oil"){
        this.formModel.ph="无";
      }
    },
    getSdsMatchUrl(value){
      this.$api.handleCnSds.cnSdsMatchUrl({substanceName:value}).then((data) => {
        if(data!=null){
          this.formModel.rowData.forEach(element=>{
            if(element.substanceName[0]==value){
              element.casNumber= data.casNumber
              element.einecsNumber= data.einecsNumber
              element.rphras= data.rphras
              element.sphrase= data.sphrase
            }
          })
        }
      });
    },
  },
  created() {
    // this.testTime =base.methods.formatTime(base.methods.getLocalTime(-7),"yyyy-MM-dd hh:mm:ss");
    let userInfo = JSON.parse(localStorage.getItem("specUserInfo"));
    if(userInfo){
      this.specUserInfo = userInfo;
    }
    this.formData.editName = userInfo.username;
    let params = { ...this.$route.query };
    if (params.id) {
      this.getDetailById(params.id);
      this.titleType = "Edit";
      this.newItemType = "Edit";
      this.showVerified = true;
    } else {
      this.titleType = "Add";
      this.newItemType = "Add";
    }

    //test
    var test = "test[0T1]testtest[01T13]t[0113]test";
    var regex2 = /\[(.+?)\]/g;
    var aa = test.match(regex2);
    aa.forEach((val,index)=>{
      if(val.indexOf('T')!=-1){
        console.info(val,index );
      }
    })
  },
  
};
</script>

<style lang="scss" scoped>
.el-tag {
  margin-left: 10px;
  margin-bottom: 10px;
}
.button-new-tag {
  margin-left: 10px;
  margin-bottom: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  margin-bottom: 10px;
  vertical-align: bottom;
}

::v-deep .select-option {
  min-width: 300px;
}
.app-new-item {
  .el-autocomplete ::v-deep .el-input__inner {
    width: 600px;
  }
  // width: 100%;
  // overflow: scroll;
  overflow-x: auto !important;
  overflow-y: auto;
  padding-top: 80px;
  .el-input,
  .el-select {
    width: 600px;
  }
  .area-input {
    height: 90px !important;
    line-height: 90px !important;
  }
  .new-item-head {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 99;
    height: 66px;
    line-height: 66px;
    font-size: 20px;
    font-weight: 600;
    color: #000;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  .new-item-content {
    margin: 40px 40px 140px;
    padding: 0 34px;
    background-color: #fff;
    > div {
      padding: 40px;
      border-bottom: 1px solid #eee;
    }
    > div:nth-last-child(1) {
      border: none;
    }
    > div > div:nth-of-type(1) {
      text-align-last: left;
      color: #000000;
      font-size: 20px;
    }
    .base-info > div:nth-of-type(2) > div,
    .general-specification > div:nth-of-type(2) > div {
      height: 60px;
      line-height: 60px;
    }
    .base-info > div:nth-of-type(2) > div > span,
    .general-specification > div:nth-of-type(2) > div > span {
      display: inline-block;
      padding-right: 10px;
      width: 300px;
      text-align: right;
    }
    .analysis > div:nth-of-type(2),
    .microbiological-tests > div:nth-of-type(2) {
      display: flex;
    }
    .analysis > div:nth-of-type(2) > div,
    .microbiological-tests > div:nth-of-type(2) > div {
      margin-top: 20px;
      display: inline-block;
      width: 50%;
      border: 1px solid #e9e9e9;
      text-align-last: left;
    }
    .analysis > div:nth-of-type(2) > div:nth-of-type(1),
    .microbiological-tests > div:nth-of-type(2) > div:nth-of-type(1) {
      margin-right: 120px;
    }
    .specification > div:nth-of-type(1),
    .test-method > div:nth-of-type(1) {
      height: 44px;
      line-height: 44px;
      padding-left: 30px;
      background-color: #f9fbfc;
      border-bottom: 1px solid #e9e9e9;
    }
    .specification > div,
    .test-method > div {
      display: flex;
      height: 60px;
      line-height: 60px;
    }
    .specification span,
    .test-method span {
      width: 25%;
      display: flex;
      justify-content: flex-end;
      text-align: right;
      padding-right: 10px;
    }
    .specification .el-input,
    .test-method .el-input,
    .specification .el-select,
    .test-method .el-select {
      width: 40%;
      text-align: left;
    }
    .other-notes > div:nth-of-type(2),
    .other-notes > div:nth-of-type(3),
    .other-notes > div:nth-of-type(4) {
      // height: 60px;
      // line-height: 60px;
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
    .other-notes > p {
      font-weight: 600;
      margin-top: 10px;
    }
    .other-notes > div:nth-of-type(2) span:nth-of-type(1),
    .other-notes > div:nth-of-type(3) span:nth-of-type(1),
    .other-notes > div:nth-of-type(4) span:nth-of-type(1) {
      display: inline-block;
      width: 200px;
      text-align: right;
      padding-right: 10px;
    }
    .other-notes > div:nth-of-type(2) span:nth-of-type(2),
    .other-notes > div:nth-of-type(3) span:nth-of-type(2) {
      display: inline-block;
      width: 500px;
      text-align: left;
      font-weight: 600;
    }
    .other-notes > div:nth-of-type(3) .el-input {
      display: inline-block;
      width: 500px;
      text-align: left;
      font-weight: 600;
    }
  }
  .velidate-item {
    ::v-deep .el-input__inner {
      border: 1px solid rgb(211, 71, 71);
    }
    ::placeholder {
      color: rgb(211, 71, 71);
    }
  }
}
</style>